.Promo {
	display: flex;
	border-top: 1px solid #e2;
	padding: 22px 25px;

	&Widget {
		box-sizing: border-box;
		display: flex;

		background-color: var(--white);
		border-radius: 16px;
		padding: spacing(3);
		margin-top: spacing(4);
	}

	&__img {
		width: 100px;

		padding-right: spacing(2.5);
	}

	&__content {
		&&--deprecated {
			text-align: center;
		}
	}

	&__header {
		display: block;
		fab-font-weight: semibold;

		fab-text: h5;
		color: var(--gray8);
		margin-bottom: spacing(0.5);

		&--deprecated {
			fab-text: h4;
		}
	}

	&__description {
		display: block;
		fab-text: small;
		padding-bottom: 8px;

		color: var(--gray9);

		&--deprecated {

			padding-bottom: spacing(1.5);
		}
	}

	&__dateTime {
		display: block;

		color: var(--gray9);
		fab-text: small;
		fab-font-weight: medium;
	}

	&__cta {
		&Wrapper {
			text-align: center;
			display: flex;
			justify-content: space-evenly;
			max-width: 70%;
			margin: spacing(3) auto 0;

			&--modal {
				margin-bottom: spacing(3);
			}
		}
		
		&SupportedLabel {
			text-align: center;
			margin: spacing(0.5) 0 spacing(1);
		}

		&Item {
			display: flex;
			flex: 1;
			flex-direction: column;
			align-items: center;
		}

		&Img {
			size: 36px;
			
			margin-bottom: spacing(1);
		}

		&Label {
			font-size: 14px;

			color: var(--link);
		}

		&Link {
			display: block;
			fab-text: small;
			padding-top: 8px;
			text-decoration: none;

			color: var(--link);
			fab-font-weight: medium;

			&:hover {
				text-decoration: underline;
			}
		}

		&ModalLink {
			color: var(--link);
		}
	}

	a {
		fab-color: info;
	}
}
