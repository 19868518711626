.CardFooter {
	position: absolute;
	top: 100%;
	left: -1px;
	right: -1px;
	padding: 15px 5px 0;
	color: #888;
	font-size: 12px;

	.CardPane__footer & {
		padding-top: 4px;
	}

	&__link {
		display: inline-block;
		text-decoration: none;
		color: inherit;
		vertical-align: middle;
		line-height: 1em;

		&:hover {
			color: $colors.link;
		}

		& + & {
			border-left: 1px solid #d9;
			margin-left: 8px;
			padding-left: 6px;
		}

		&--privacy {
			> .bicon-privacy {
				vertical-align: baseline;
				margin-right: 4px;

				&,
				&:hover {
					display: inline-block;
					width: 8px;
					height: 11px;
					background-image: url("https://staticfe.bamboohr.com/resources/images/bicons/privacy-icon.png");
					background-position: top 0 center;
				}
			}
		}

		&--terms {
		}

		&--powered {
			ba-icon {
				fill: #888;
				display: inline-block;
				transform: translateY(-2px);
			}
		}
	}

	&__powered {
		float: right;
	}
}
